<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <!-- :class="{ 'pl-0 pt-0': loading }" -->

        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.prevent="openModal"
        >
          <!-- <v-progress-circular
            indeterminate
            class="mt-0"
            color="#704ad1"
            v-if="loading"
          ></v-progress-circular> -->
          <font-awesome-icon icon="cloud-download-alt" />
        </div>
      </template>
      <span>Télécharger la Liste des écritures comptables avec anomalie</span>
    </v-tooltip>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      scrollable
      hide-overlay
      persistent
      content-class="custom-vuetify-dialog-header"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Télécharger la Liste des écritures comptables avec anomalie
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text
          class="body-card center-text mt-2 custom-expansion-ecriture-compta"
        >
          <v-form>
            <div v-if="computedCheckFilter" class="message-error-modal mt-2">
              <div>
                Si aucun de ces filtres n'est sélectionné, le fichier téléchargé
                contiendra toutes les factures présentant une anomalie
              </div>
            </div>
            <!-- SOCIETE  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="filiale_id"
                  :items="computedGetVendeurs"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- CLIENT  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="acheteur_id"
                  :items="computedGetVendeurs"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Client"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvé"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- STATUT  -->
            <v-row class="row-top">
              <v-col cols xs="12" sm="12" md="12">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="type_facture"
                  :items="ListType"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Type"
                  item-text="text"
                  multiple
                  chips
                  return-object
                  outlined
                  :deletable-chips="true"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  item-value="value"
                  :small-chips="true"
                  :clearable="true"
                  :auto-focus="false"
                  no-data-text="Aucun Type trouvé"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="row-top">
              <!-- DATE DEBUT -->
              <v-col cols xs="6" sm="6" md="6" class="date-input-vuetify">
                <v-menu
                  v-model="isDatePickerDebutVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_debut"
                      label="Date début"
                      outlined
                      readonly
                      hide-details
                      clearable
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="date_debut = null"
                      color="#704ad1"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_debut"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- DATE FIN -->
              <v-col class="date-input-vuetify " cols xs="6" sm="6" md="6">
                <v-menu
                  v-model="isDatePickerFinVisible"
                  :close-on-content-click="false"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      label="Date fin"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      v-bind="attrs"
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      @click:clear="date_fin = null"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date_fin"
                    no-title
                    locale="fr"
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="#704ad1"
                  ></v-date-picker> </v-menu></v-col
            ></v-row>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handelClickExport"
            :loading="loading"
            :class="{ loader: loading }"
            type="submit"
          >
            Générer
          </v-btn>
          <v-btn text @click="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      error: null,
      filiale_id: [],
      acheteur_id: [],
      type_facture: [],
      date_debut: null,
      date_fin: null,
      ListType: [
        { value: 'Facture', text: 'Facture' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false
    };
  },
  computed: {
    ...mapGetters(['getFiliaeOfResponsable']),
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedCheckFilter() {
      return (
        this.filiale_id.length == 0 ||
        this.acheteur_id.length == 0 ||
        this.type_facture.length == 0 ||
        this.date_debut == null ||
        this.date_fin == null
      );
    }
  },
  methods: {
    ...mapActions(['exportExcelFactureComptaAvecAnomalie']),
    async handelClickExport() {
      this.loading = true;
      let payload = {};
      if (this.date_debut) {
        payload.date_debut = this.date_debut;
      }
      if (this.date_fin) {
        payload.date_fin = this.date_fin;
      }
      if (this.filiale_id.length) {
        payload.filiale_id = this.filiale_id.map(i => i.value);
      }
      if (this.acheteur_id.length) {
        payload.acheteur_id = this.acheteur_id.map(i => i.value);
      }
      if (this.type_facture.length) {
        payload.type_facture = this.type_facture.map(i => i.value);
      }
      const response = await this.exportExcelFactureComptaAvecAnomalie(payload);
      if (response.succes) {
        this.resetModal();
        let type = response.data.icon ? response.data.icon : 'success';
        let message = response.data.message ? response.data.message : 'OK';
        this.$alert('', message, type);
      } else {
      }
      this.loading = false;
    },
    openModal() {
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
      this.resetModal();
    },
    resetModal() {
      this.loading = false;
      this.dialog = false;
      this.error = null;
      this.filiale_id = [];
      this.acheteur_id = [];
      this.type_facture = [];
      this.date_debut = null;
      this.date_fin = null;
    }
  }
};
</script>

<style></style>
